import { Button, Card, Grid, Snackbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import Loader from '../../../components/Loader';
import PatientInfo from '../../../components/PatientInfo';
import { IRequest, RequestStatus, RequestType, ServiceType } from './types';

import ShowPatientRecords from '../../../components/investigation/show/single/show_patient_records';
import { TYPE_FILL_IMG_SURVEY, TYPE_FILL_LAB_SURVEY, TYPE_FILL_SHOE_SURVEY, TYPE_FILL_SURGERY_SURVEY, TYPE_REQUEST_LAB, TYPE_REQUEST_SHOE, TYPE_REQUEST_SURGERY } from '../../../constants';
import { IResearcher, ISurvey, SnackbarTypeSeverity } from '../../../constants/types';
import FillDataCollection from '../FillDataCollection';
import { usePatients, useRequest, useSnackBarState, useUpdateEffect } from '../../../hooks';
import { Alert } from '@mui/material';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import RequestInfo from './RequestInfo';
import { PERMISSION } from '../../../components/investigation/share/user_roles';
import { RequestStatusToChip } from './RequestTable';
import { nextPosibleSurgeryStatus, SurgeryStatus } from './utils';
import Modal from '../../../components/general/modal';



interface RequestSingleProps { 
    idRequest:number,
    uuidInvestigation:string,
    permissions:string[],
    surveys:ISurvey[],
    country:string,
    researcher:IResearcher, 
    requestServiceType:ServiceType,
    requestSentCallBack:(typeRequest:number)=>void,
}

interface RequestActionsProps {
    request: IRequest;
    requestServiceType: ServiceType;
    onEdit?: () => void;
    updateRequestStatus: (idRequest: number, status: SurgeryStatus) => Promise<void>;
    setShowSnackbar: (snackbar: { show: boolean; message?: string; severity?: SnackbarTypeSeverity }) => void;
}

const RequestActions: React.FC<RequestActionsProps> = ({ 
    request, 
    requestServiceType, 
    onEdit, 
    updateRequestStatus,
    setShowSnackbar 
}) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nextStatus, setNextStatus] = useState<SurgeryStatus | null>(null);

    const handleStatusUpdate = async () => {
        setLoading(true);
        try {
            await updateRequestStatus(request.id, nextStatus as SurgeryStatus);
            setShowSnackbar({
                show: true,
                message: "pages.hospital.services.request.status_updated",
                severity: SnackbarTypeSeverity.SUCCESS
            });
        } catch (error) {
            setShowSnackbar({
                show: true,
                message: "general.error",
                severity: SnackbarTypeSeverity.ERROR
            });
        }
        setLoading(false);
        setModal(false);
    };

    if (requestServiceType !== ServiceType.SURGERY || request.status === RequestStatus.COMPLETED) {
        return null;
    }

    const nextPossibleStatus = useMemo(() => nextPosibleSurgeryStatus(request.status as SurgeryStatus), [request.status]);

    return (
        <>
        <Modal key="modal"
                open={modal}
                closeModal={() => setModal(false)}
                confirmAction={handleStatusUpdate}
                disabled={loading}
                title={<Translate id="pages.hospital.services.request.status_update.title" />}>
                    <Typography variant="h6" gutterBottom>
                        <Translate id="pages.hospital.services.request.status_update.modal" />
                        <RequestStatusToChip status={nextStatus as SurgeryStatus} />
                    </Typography>
            </Modal>
        <Grid item xs={12}>
            <Card style={{ padding: "1rem" }}>
                <Typography variant="h6" style={{fontWeight:"bold"}} gutterBottom>
                    <Translate id="pages.hospital.services.request.status_update.description" />
                </Typography>
                <Grid container spacing={2}> 
                        <>
                            <Grid item>
                                {
                                    nextPossibleStatus.map((status) => <Button onClick={() => {setModal(true); setNextStatus(status)}}><RequestStatusToChip status={status} /></Button>)
                                }
                            </Grid>        
                        </>
                </Grid>
            </Card>
        </Grid>
        </>
    );
};

const RequestSingle: React.FC<RequestSingleProps> = ({ idRequest, researcher, uuidInvestigation, country,
                                                        requestServiceType, permissions, surveys, requestSentCallBack }) => {
    const {request, loadingRequest} = useRequest(idRequest);
    const { patients, loadingPatients } = usePatients(uuidInvestigation);
    const patientsSubmissions = useSelector((state:any) => state.patientsSubmissions)
    const [loading, setLoading] = useState(false);
    const [submissionData, setSubmissionData] = useState<{submission:any} | null>(null);
    const [editData, setEditData] = useState(false);
    const [snackbar, setShowSnackbar] = useSnackBarState(); 

    useEffect(() => {
        if(request && patientsSubmissions.loading === false){
            setEditData(false);
            if(patientsSubmissions.error){
                setShowSnackbar({show:true, message:"general.error", severity:SnackbarTypeSeverity.ERROR});
            }
            else{
                setShowSnackbar({show:true, severity:SnackbarTypeSeverity.SUCCESS, message:"pages.hospital.services.request.success"});
            }
        }
        
    }, [patientsSubmissions])

    useEffect(() => {
        async function fetchSubmission(){
            setLoading(true);
        
            const response = await axios(`${import.meta.env.VITE_APP_API_URL}/researcher/investigation/${uuidInvestigation}/submission/${request?.submissionPatient.id}?findRequests=true`, { headers: {"Authorization" : localStorage.getItem("jwt")} })
                    .catch(err => {console.log('Catch', err); return err;});
            if(response.status === 200){
                setSubmissionData(response.data);
            }
            setLoading(false);
        }
        if(request?.submissionPatient){
            fetchSubmission();
        }
        
    }, [request])

    const updateRequestStatus = async (idRequest: number, status: SurgeryStatus) => {
        try {
            const response = await axios.put(
               
                `${import.meta.env.VITE_APP_API_URL}/hospital/${uuidInvestigation}/surgery/request/${idRequest}/status`,
                { status },
                { headers: { "Authorization": localStorage.getItem("jwt") } }
            );
            
            if (response.status === 200) {
                // Update the local request data
                if (request) {
                    const updatedRequest = { ...request, status };
                    // You'll need to add a way to update the request state here
                    // This could be through a setter function or Redux action
                }
            }
        } catch (error) {
            console.error('Error updating request status:', error);
            throw error;
        }
    };

    function renderCore(){
        const survey = surveys.find((survey) => {
            if(requestServiceType === ServiceType.LABORATORY){
                return survey.type === TYPE_FILL_LAB_SURVEY;
            }
            else if(requestServiceType === ServiceType.SHOE){
                return survey.type === TYPE_FILL_SHOE_SURVEY; 
            }
            else if(requestServiceType === ServiceType.SURGERY){
                return survey.type === TYPE_FILL_SURGERY_SURVEY;
            }
            else{
                return survey.type === TYPE_FILL_IMG_SURVEY;
            }
            
        })
        if(!survey){
            return <Typography variant="h4">The survey could not be found</Typography>
        }
        if(!permissions.includes(PERMISSION.UPDATE_LAB_REQUESTS) && !submissionData){
            return <Typography variant='body2'><Translate id="hospital.request.request_not_ready" /></Typography>;
        }
        else if(!editData && request?.submissionPatient && submissionData){ 
            const newSubmission = patientsSubmissions.data && patientsSubmissions.data[request?.requestsServiceInvestigation[0].patientInvestigation.uuid] && patientsSubmissions.data[request?.requestsServiceInvestigation[0].patientInvestigation.uuid][survey.uuid] ?  patientsSubmissions.data[request?.requestsServiceInvestigation[0].patientInvestigation.uuid][survey.uuid].submissions.find((submission:any) => submission.id === request?.submissionPatient.id) : null;
            const submissionDataLocal = newSubmission ? newSubmission : submissionData.submission;
            return (
                <ShowPatientRecords permissions={permissions} survey={survey} forceEdit={request.status !== RequestStatus.COMPLETED}
                    mode="elements" callBackEditSubmission={() => setEditData(true)} idSubmission={request?.submissionPatient.id}
                    submissions={[submissionDataLocal]} surveys={surveys} />
            )
        }
        else{
            return (
                <FillDataCollection key={survey?.uuid} initData = {editData && submissionData ? submissionData.submission : null} 
                    dataCollection={survey} hideCollectionName={true} requestServiceId={idRequest} 
                    idSubmission={submissionData ? submissionData.submission.id : null}
                    country={country} researcher={researcher} alterSubmitButton={requestServiceType !== ServiceType.SURGERY ? "pages.hospital.services.save_and_complete" : null}
                    uuidPatient={request?.requestsServiceInvestigation[0].patientInvestigation.uuid} 
                    uuidInvestigation={uuidInvestigation}
                    callBackDataCollection={() => console.log("Data saved")} />
            )
        }
    }

    function handleCloseSnackBar(){
        setShowSnackbar({show:false});
        if(snackbar.severity === SnackbarTypeSeverity.SUCCESS && request !== null){
            requestSentCallBack(request?.requestsServiceInvestigation[0].serviceInvestigation.service.type);
        }
    }

    if(loading || loadingRequest || (patientsSubmissions.loading) || loadingPatients){
        return <Loader />
    }
    else if(!request){
        return <Typography variant="h4">Request not found</Typography>
    }
    return (
        <>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={snackbar.show}
                autoHideDuration={4000}
                onClose={handleCloseSnackBar}
                >
                <Alert severity={snackbar.severity}>
                        <Translate id={snackbar.message} />                            
                    </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card style={{padding:"1rem"}}>
                        <PatientInfo uuidPatient={request.requestsServiceInvestigation[0].patientInvestigation.uuid}/>
                    </Card>
                    
                </Grid>
                <Grid item xs={12}>
                    <Card style={{padding:"1rem"}}>
                        <RequestInfo request={request} />
                    </Card>
                </Grid>
                <RequestActions 
                    requestServiceType={requestServiceType}
                    request={request}
                    onEdit={() => setEditData(true)}
                    updateRequestStatus={updateRequestStatus}
                    setShowSnackbar={setShowSnackbar}
                />
            </Grid>
            {
                renderCore()
            }
        </>
    );
};

export default RequestSingle;
