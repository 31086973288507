import { RequestStatus } from "./types";

export type SurgeryStatus = RequestStatus.ANESTHESIOLOGIST_ACCEPTED | RequestStatus.COMPLETED | RequestStatus.PAYMENT_COMPLETED | RequestStatus.READY_FOR_SURGERY | RequestStatus.CANCELED | RequestStatus.PENDING_ANESTHESIOLOGIST | RequestStatus.ANESTHESIOLOGIST_REJECTED | RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS | RequestStatus.COMPLETED | RequestStatus.PENDING_PAYMENT | RequestStatus.ACCEPTED;
export const surgeryStatusValues: SurgeryStatus[] = [
    RequestStatus.ANESTHESIOLOGIST_ACCEPTED,
    RequestStatus.PAYMENT_COMPLETED,
    RequestStatus.READY_FOR_SURGERY,
    RequestStatus.COMPLETED,
    RequestStatus.PENDING_ANESTHESIOLOGIST,
    RequestStatus.PENDING_PAYMENT,
    RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS,
    RequestStatus.ANESTHESIOLOGIST_REJECTED,
    RequestStatus.CANCELED
    

];

export function getRequestStatusName(status: RequestStatus): string {
    return RequestStatus[status];
}


export function nextPosibleSurgeryStatus(request:SurgeryStatus):SurgeryStatus[]{
    switch(request){   
        case RequestStatus.PENDING_PAYMENT: 
            return [RequestStatus.PAYMENT_COMPLETED, RequestStatus.CANCELED]
        case RequestStatus.PAYMENT_COMPLETED:
            return [RequestStatus.PENDING_ANESTHESIOLOGIST, RequestStatus.CANCELED]
        case RequestStatus.PENDING_ANESTHESIOLOGIST: //Pending Anestesist approval
            return [RequestStatus.ANESTHESIOLOGIST_ACCEPTED, RequestStatus.ANESTHESIOLOGIST_REJECTED, RequestStatus.CANCELED];
        case RequestStatus.ANESTHESIOLOGIST_ACCEPTED:
            return [RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS, RequestStatus.READY_FOR_SURGERY, RequestStatus.CANCELED];      
        case RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS: //Pending buy protesis
            return [RequestStatus.READY_FOR_SURGERY, RequestStatus.CANCELED];
        case RequestStatus.READY_FOR_SURGERY:
            return [RequestStatus.COMPLETED, RequestStatus.CANCELED];
    }
    return [request];
}