import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography, IconButton } from '@mui/material';
import { blue, brown, green, orange, red, yellow } from '@mui/material/colors';
import { ContactSupportOutlined, Clear as ClearIcon } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { EnhancedTable } from '../../../components/general/EnhancedTable';
import { TypographyStyled } from '../../../components/general/mini_components';
import { ColourChip } from '../../../components/general/mini_components-ts';
import Loader from '../../../components/Loader';
import { IResearcher, ISurvey } from '../../../constants/types';
import { useDepartments } from '../../../hooks';
import { dateAndTimeFromPostgresString, decryptSinglePatientData, fullDateFromPostgresString, getDepartmentFromUnit, getHealthIDLabel, getPatientID, patientFullName, researcherFullName, stringDatePostgresToDate } from '../../../utils/index.jsx';
import axios from '../../../utils/axios';
import { useRequests } from '../../../hooks/useRequests';
import { useSurgeryAppointments } from '../../../hooks/useSurgeryAppointments';

import { IRequest, RequestStatus, RequestType, ServiceType } from './types';
import SearchBox from '../../../components/general/SearchBox';
import { getRequestStatusName, surgeryStatusValues } from './utils';
import { fetchPatient } from '../../../db';

const requestGroupStatus = (request:IRequest) => {
    // Si una request es completada, el estado es completada. Si no, devuelvo los estados de las requests
    if(request.requestsServiceInvestigation.some(request => request.status === RequestStatus.COMPLETED)){
        return <RequestStatusToChip status={RequestStatus.COMPLETED} />
    }
    else{
        return request.requestsServiceInvestigation.map((req) => {
            return <RequestStatusToChip status={req.status} />
        }) 
    }
    
}

export const statusToColor = (status:RequestStatus) => {
    let colour = "#000";
    switch(status){
        case RequestStatus.PENDING_APPROVAL:
        case RequestStatus.PENDING_PAYMENT:
        case RequestStatus.IN_PROGRESS:
        case RequestStatus.PENDING_ANESTHESIOLOGIST:
        case RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS:
            colour = yellow[900];
            break;
        case RequestStatus.COMPLETED:     
        case RequestStatus.ACCEPTED: 
            colour = blue[900];
            break;
        case RequestStatus.SOME_ACCEPTED:
        case RequestStatus.INCOMPLETE_ACCEPTED:
            colour = blue[600];
            break;
    
        case RequestStatus.ANESTHESIOLOGIST_ACCEPTED:
        case RequestStatus.PAYMENT_COMPLETED:
        case RequestStatus.READY_FOR_SURGERY:
            colour = green[700];            
            break;
        case RequestStatus.CANCELED:
        case RequestStatus.DENIED:
        case RequestStatus.INCOMPLETE:
        case RequestStatus.ANESTHESIOLOGIST_REJECTED:
            colour = red[500];            
            break;  
    }
    return colour;
}

export const RequestStatusToChip:React.FC<{status:RequestStatus}> = ({status}) =>  {
        let colour = statusToColor(status);
        let translation = RequestStatus[status]
       
        return <ColourChip rgbcolor={colour} label={<Translate id={`pages.hospital.services.request_status.${translation}`}  />}/>
}

export const serviceToColor = (type:ServiceType) => {
    let colour = null
    switch(type){
        case ServiceType.LABORATORY:
            colour = orange[900];
            break;
        case ServiceType.IMAGING:
            colour = yellow[900];
            break;
        case ServiceType.PHARMACY:
            colour = green[900];
            break;
        case ServiceType.SHOE:
            colour = brown[900];
            break;
        case ServiceType.INTERCONSULT:
            colour = red[100];
            break;
    }
    return colour;
}

const ServiceTypeToChip:React.FC<{type:ServiceType}> = ({type}) =>  {
    let colour = serviceToColor(type);
    let translation = ServiceType[type];
    return <ColourChip rgbcolor={colour} label={<Translate id={`pages.hospital.services.service_type.${translation}`}/>} />
}


interface RequestTableProps {
    requestServiceType:ServiceType,
    activeLanguageCode:string,
    typeRequest:RequestType,
    uuidPatient?:string,
    noSearchBox?:boolean,
    uuidInvestigation:string,
    showActions?:boolean,
    surveys?:ISurvey[],
    searchFor:string,
    fillPending?:boolean,
    encryptionData?:{
        encryptedKeyUsed:number,
        keyResearcherInvestigation:string,
        permissions:any[],
        personalFields:any[],
    },
    callBackRequestSelected:(request:IRequest)=>void,
    createAppointmentFromRequestCallback:(requestId:number) => void,
    goToAppointmentFromRequestCallback:(uuidAppointment:string) => void
}

type RequestTablePharmacyProps = Pick<RequestTableProps, 'requestServiceType' | 'uuidInvestigation' | 'callBackRequestSelected'>

const ACTIONABLE_REQUESTS = [RequestStatus.PENDING_APPROVAL, RequestStatus.ACCEPTED, RequestStatus.COMPLETED, RequestStatus.IN_PROGRESS, RequestStatus.PENDING_ANESTHESIOLOGIST, RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS];

export const RequestTableService:React.FC<RequestTableProps> = ({requestServiceType, uuidPatient, uuidInvestigation, showActions, activeLanguageCode,
                                                                    surveys, fillPending, encryptionData, callBackRequestSelected, 
                                                                    createAppointmentFromRequestCallback, goToAppointmentFromRequestCallback}) => {
    
    return <RequestTable requestServiceType={requestServiceType} uuidPatient={uuidPatient} searchFor="general.patient"
                uuidInvestigation={uuidInvestigation} showActions={showActions} surveys={surveys} typeRequest={RequestType.SERVICES}
                fillPending={fillPending} encryptionData={encryptionData} activeLanguageCode={activeLanguageCode}
                createAppointmentFromRequestCallback={createAppointmentFromRequestCallback}
                goToAppointmentFromRequestCallback={goToAppointmentFromRequestCallback}
                callBackRequestSelected={callBackRequestSelected} />
}

export const RequestTablePharmacy:React.FC<RequestTablePharmacyProps> = ({requestServiceType, uuidInvestigation, callBackRequestSelected, activeLanguageCode}) => {
    return <RequestTable requestServiceType={requestServiceType} searchFor="hospital.search_box.staff" typeRequest={RequestType.PHARMACY}
                uuidInvestigation={uuidInvestigation} activeLanguageCode={activeLanguageCode}
                callBackRequestSelected={callBackRequestSelected} />
}

const RequestTable: React.FC<RequestTableProps> = ({
    typeRequest, 
    requestServiceType,
    searchFor, 
    noSearchBox, 
    surveys, 
    activeLanguageCode,
    uuidPatient, 
    uuidInvestigation, 
    encryptionData, 
    showActions,
    fillPending,  
    callBackRequestSelected, 
    createAppointmentFromRequestCallback,
    goToAppointmentFromRequestCallback
}) => {
    const { 
        requests, 
        loading: requestsLoading, 
        error: requestsError 
    } = useRequests({
        requestServiceType,
        uuidPatient,
        uuidInvestigation,
        typeRequest
    });

    const { 
        appointmentsByRequest, 
        loading: appointmentsLoading, 
        error: appointmentsError 
    } = useSurgeryAppointments({
        uuidInvestigation,
        enabled: requestServiceType === ServiceType.SURGERY
    });

    const enrichedRequests = useMemo(() => {
        if (!requests) return [];

        if(appointmentsByRequest.length === 0){
            return requests;
        }
        
        return requests.map(request => {
            const appointmentWithRequest = appointmentsByRequest[request.id];
            return {
                ...request,
                appointment: appointmentWithRequest ? appointmentWithRequest : null
            }
        });
    }, [requests, appointmentsByRequest]);


    if (requestsLoading || appointmentsLoading || !requests) {
        return <Loader />;
    }

    if (requestsError || appointmentsError) {
        console.error('Errors:', { requestsError, appointmentsError });
        // You might want to show an error message to the user
    }

    if (requests.length > 0) {
        return (
            <RequestTableComponent 
                searchFor={searchFor} 
                noSearchBox={noSearchBox} 
                requestServiceType={requestServiceType} 
                surveys={surveys} 
                fillPending={fillPending}  
                encryptionData={encryptionData} 
                uuidPatient={uuidPatient} 
                showActions={showActions}
                activeLanguageCode={activeLanguageCode}
                loading={requestsLoading || appointmentsLoading} 
                requests={enrichedRequests} 
                callBackRequestSelected={callBackRequestSelected}
                createAppointmentFromRequestCallback={createAppointmentFromRequestCallback}
                goToAppointmentFromRequestCallback={goToAppointmentFromRequestCallback}
            />
        );
    }

    return (
        <TypographyStyled variant="body2">
            <Translate id="pages.hospital.services.no_requests" />
        </TypographyStyled>
    );
};

export default RequestTable;

interface RequestTableComponentProps extends Omit< RequestTableProps, 'uuidInvestigation' >{
    requests:IRequest[],
    loading:boolean,
    createAppointmentFromRequestCallback:(requestId:number) => void,
    goToAppointmentFromRequestCallback:(uuidAppointment:string) => void
}

interface Row {
    id: number;
    nhc: string | number;
    service: string | JSX.Element;
    department: string;
    patient: string;
    researcher: string;
    status: JSX.Element;
    statusValue: RequestStatus;
    type: string | JSX.Element;
    date: string;
}
export const RequestTableComponent: React.FC<RequestTableComponentProps> = ({ uuidPatient, searchFor, requests, requestServiceType, noSearchBox,
                                                                                encryptionData, loading, callBackRequestSelected, createAppointmentFromRequestCallback, 
                                                                                goToAppointmentFromRequestCallback, activeLanguageCode }) => {
    
    
    const {departments} = useDepartments();    
    const [rows, setRows] = React.useState<Row[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<Row[]>([]);
    const [searchText, setSearchText] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState<RequestStatus[]>([]);
    const [scheduledFilter, setScheduledFilter] = React.useState<boolean>(false);
    const [stringHealthID, setStringHealthID] = React.useState(getHealthIDLabel());

    const [proceduresApplied, setProceduresApplied] = React.useState<string[]>([]);
    const [procedureFilter, setProcedureFilter] = React.useState<string>("");
    const [staffFilter, setStaffFilter] = React.useState<string>("");
    const [staffsApplied, setStaffsApplied] = React.useState<IResearcher[]>([]);

    useEffect(() => {
        const uniqueProcedures = requests.reduce((acc: string[], request) => {
            const procedureName = request.requestsServiceInvestigation[0].serviceInvestigation.service.name;
            if (!acc.includes(procedureName)) {
                acc.push(procedureName);
            }
            return acc;
        }, []);
        setProceduresApplied(uniqueProcedures);
    }, [requests]);

    useEffect(() => {
        const uniqueStaffs = requests.reduce((acc: IResearcher[], request) => {
            const exists = acc.some(staff => staff.uuid === request.researcher.uuid);
            if (!exists) {
                acc.push(request.researcher);
            }
            return acc;
        }, []);
        setStaffsApplied(uniqueStaffs);
    }, [requests]);

    useEffect(() => {
        const loadHealthID = async () => {
            if (requests.length > 1) {
                const patient = requests[0].requestsServiceInvestigation[0].patientInvestigation;
                const patientData = await fetchPatient(patient.uuid);
                const label = getHealthIDLabel(patientData);
                setStringHealthID(label);
            }
        };
        loadHealthID();
    }, [activeLanguageCode, requests]);

    useEffect(() => {
        setFilteredRows(rows.filter((row: Row) => {
            const searchItem = requestServiceType === ServiceType.PHARMACY ? row.researcher : row.patient;
            const healthIdFiltered = row.nhc.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
            const statusFiltered = statusFilter.length === 0 ? true : statusFilter.includes(row.statusValue);
            const scheduledFiltered = scheduledFilter ? row.scheduled : true;
            const procedureFiltered = procedureFilter !== "" ? row.serviceName.toString().toLocaleLowerCase().includes(procedureFilter.toLocaleLowerCase()) : true;
            const staffFiltered = staffFilter !== "" ? row.uuidResearcher === staffFilter : true;
            const filtered = (healthIdFiltered || searchItem.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) && statusFiltered && scheduledFiltered && procedureFiltered && staffFiltered;
            return filtered;
                        
        }));
        
    }, [searchText, statusFilter, scheduledFilter, procedureFilter]);
    
    useEffect(() => {
        const loadRows = async () => {
            const rowPromises = requests.sort((reqA, reqB) => 
                stringDatePostgresToDate(reqB.updatedAt).getTime() - stringDatePostgresToDate(reqA.updatedAt).getTime()
            ).map(async (request) => {            
                const uuidPatient = request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation[0].patientInvestigation.uuid : "";
                const patient = await fetchPatient(uuidPatient);
                
                return {
                    id: request.id,
                    nhc: patient ? getPatientID(patient) : "",
                    service: request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation.length > 1 ? <ColourChip rgbcolor={serviceToColor(request.type)} label={<Translate id="general.several" />} /> : <ColourChip rgbcolor={serviceToColor(request.type)} label={request.requestsServiceInvestigation[0].serviceInvestigation.service.name} /> : "",
                    serviceName: request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation[0].serviceInvestigation.service.name : "",
                    department:request.departmentRequest ? request.departmentRequest.name : "",
                    patient: patient ? patientFullName(patient?.personalData) : "",
                    dob : patient?.personalData.birthdate ? new Date(patient?.personalData.birthdate).toLocaleDateString(activeLanguageCode) : "",
                    researcher: researcherFullName(request.researcher),    
                    uuidResearcher : request.researcher.uuid,        
                    status: <RequestStatusToChip status={request.status} />,
                    dateCompleted: request.completedAt ? fullDateFromPostgresString(activeLanguageCode, request.completedAt) : "",
                    dateCompletedRaw: request.completedAt ? request.completedAt : null,
                    statusValue: request.status,
                    type : request.requestsServiceInvestigation[0] ? <ServiceTypeToChip type={request.requestsServiceInvestigation[0].serviceInvestigation.service.type} /> : "", 
                    scheduled : request.appointment ? true : false,
                    totalDays: request.completedAt ? Math.round((new Date().getTime(request.completedAt) - new Date(request.createdAt).getTime()) / (1000 * 60 * 60 * 24)) : Math.round((new Date().getTime() - new Date(request.createdAt).getTime()) / (1000 * 60 * 60 * 24)),
                    dateAdded: fullDateFromPostgresString(activeLanguageCode, request.createdAt),
                    dateCreatedRaw: dateAndTimeFromPostgresString(activeLanguageCode, request.createdAt)
                }
            });
            
            const rows = await Promise.all(rowPromises);
            setRows(rows);
            setFilteredRows(rows);
        };

        loadRows();
    }, [requests]);

    function applyFilterGeneral(filter:number[] , functionAdd: (value:number[]) => void, value:number){
        if(filter && filter.includes(value)){
            if(filter.length === 1){
                functionAdd([]);
            }
            else{
                functionAdd(filter.filter((s) => s !== value));
            }
        }
        else if(filter){
            functionAdd([...filter, value]);
        }
        else{
            functionAdd([value]);
        }
    }

    function applyStatusFilter(status:RequestStatus){
        applyFilterGeneral(statusFilter, setStatusFilter, status);
    }

    function iconType(id:number){
        const request = requests.find((req) => req.id === id);
        if(request && request.appointment){
            return "appointment_available";
        }
        return "appointment";
    }

    function colorOfAppointment(id:number){
        const request = requests.find((req) => req.id === id);
        if(request && request.appointment){
            return "green";
        }
        return "black";
    }

    function handleAppointmentClick(id:number){
        const request = requests.find((req) => req.id === id);
        if(!request){
            console.log("No se encontró la solicitud");
        }
        else if(request.appointment){
            console.log("Ya existe una cita para esta solicitud");
            goToAppointmentFromRequestCallback(request.appointment.uuid);
        }
        else{
            createAppointmentFromRequestCallback(id)
        }
    }
    function fillRequest(id:number){
        const request = requests.find((req) => req.id === id);
        if(request){
            //Lo comento porque no entiendo por que solo permito entrar para algunos casos
            // const hasActionableRequests = ACTIONABLE_REQUESTS.filter(function(status) {
            //     if(serviceType === RequestType.PHARMACY){
            //         return status === request.status
            //     }
            //     return request.requestsServiceInvestigation.findIndex((req) => req.status === status) !== -1
            // }).length > 0;
            const hasActionableRequests = true;
            if(hasActionableRequests){
                callBackRequestSelected(request);
            }
            else{
                console.log("No hay survey");
            }
        }
        else{
            console.log("No hay nada que hacer con esa solicitud");
        }
        
    }
    if(loading || !departments){
        return <Loader />
    }
    else if(requests.length === 0){
        return(
            <div className="text-center">
                <h4><Translate id="pages.hospital.services.no_requests" /></h4>
            </div>
        )
    }
    let headCells;
    if(requestServiceType === ServiceType.PHARMACY){
        headCells = [
            { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
            {id : 'researcher', label: <Translate id="hospital.staff" />, alignment:'left'},
            {id : 'status', label: <Translate id="pages.hospital.services.status" />, alignment:'left'},
            {id : 'department', label: <Translate id="hospital.departments.department" />, alignment:'left'},
            {id : 'date', label: <Translate id="general.date" />, alignment:'left'},
        ];
    }
    else{
        headCells = [
            
                    {id : 'researcher', label: <Translate id="hospital.staff" />, alignment:'left'},
                    {id : 'department', label: <Translate id="hospital.departments.department" />, alignment:'left'},
                    {id : 'service', label: <Translate id={`pages.hospital.services.${requestServiceType === ServiceType.SURGERY ? "surgery" : "service"}`} />, alignment:'left'},
                    {id : 'type', label: <Translate id="pages.hospital.services.type" />, alignment:'left'},
                    {id : 'dateAdded', label: <Translate id="general.date_added" />, alignment:'left'},
                    {id : 'dateCompleted', label: <Translate id="hospital.request.dateCompleted" />, alignment:'left'},
                    {id : 'totalDays', label: <Translate id="hospital.request.total_days" />, alignment:'left'},
                    {id : 'status', label: <Translate id="pages.hospital.services.status" />, alignment:'left'}];
        if(!uuidPatient){
            headCells.splice(headCells.findIndex((head) => head.id === 'type'), 1); 
            headCells.splice(0, 0, {id : 'nhc', label: <Translate id={stringHealthID} />, alignment:'left'})
            headCells.splice(1, 0, {id : 'patient', label: <Translate id="general.patient" />, alignment:'left'})
            headCells.splice(2, 0,{id : 'dob', label: <Translate id="investigation.create.personal_data.short-fields.birthdate" />, alignment:'left'})
        }
    }
     
    let actions = null;
    if(requestServiceType === ServiceType.SURGERY){
        actions = [{type : (id:number) => iconType(id), color: (id:number) => colorOfAppointment(id), func : (id:number) => handleAppointmentClick(id), check: (row:Row) => row.statusValue !== RequestStatus.CANCELED}]
    }
    let filterItems = surgeryStatusValues.map((status) => ({label:`pages.hospital.services.request_status.${getRequestStatusName(status)}`, selected: statusFilter.includes(status), value:status, color:statusToColor(status), callBack:() => applyStatusFilter(status)}))
    filterItems.push({label:`pages.hospital.services.request_status.scheduled`, selected: scheduledFilter, value:scheduledFilter, color:statusToColor(RequestStatus.READY_FOR_SURGERY), callBack:() => setScheduledFilter(!scheduledFilter)})
    return (
        <Grid container spacing={2}>
            {
                !noSearchBox && 
                <SearchBox textField={{label:searchFor, callBack:setSearchText}}
                    activeFilters={statusFilter}
                    extraFilters={<Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <FormControl variant="outlined" style={{ minWidth: '300px' }}>
                                                <InputLabel htmlFor="select-procedure" id="select-procedure-label" style={{backgroundColor:'white'}}>
                                                    <Translate id="hospital.request.select_procedure" />
                                                </InputLabel>
                                                <Select
                                                    labelId="select-procedure-label"
                                                    id="select-procedure"
                                                    style={{ minWidth: '200px' }}
                                                    value={procedureFilter}
                                                    onChange={(e) => setProcedureFilter(e.target.value as string)}
                                                    displayEmpty
                                                    renderValue={(value) => value || "Select Procedure"}
                                                    endAdornment={
                                                        procedureFilter ? (
                                                            <IconButton
                                                                size="small"
                                                                style={{ marginRight: 8 }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setProcedureFilter("");
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ) : null
                                                    }
                                                >
                                                    {proceduresApplied.map((procedure) => (
                                                        <MenuItem value={procedure}>{procedure}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl variant="outlined" style={{ minWidth: '300px' }}>
                                                <InputLabel htmlFor="select-staff" id="select-staff-label" style={{backgroundColor:'white'}}>
                                                    <Translate id="hospital.request.select_staff" />
                                                </InputLabel>
                                                <Select
                                                    labelId="select-staff-label"
                                                    id="select-staff"
                                                    style={{ minWidth: '200px' }}
                                                    value={staffFilter}
                                                    onChange={(e) => setStaffFilter(e.target.value as string)}
                                                    displayEmpty
                                                    renderValue={(value) => {
                                                        if (!value) return "Select Staff";
                                                        const staff = staffsApplied.find(s => s.uuid === value);
                                                        return staff ? researcherFullName(staff) : "Select Staff";
                                                    }}
                                                    endAdornment={
                                                        staffFilter ? (
                                                            <IconButton
                                                                size="small"
                                                                style={{ marginRight: 8 }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setStaffFilter("");
                                                                }}
                                                            >
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        ) : null
                                                    }
                                                >
                                                    {staffsApplied.map((researcher) => (
                                                        <MenuItem value={researcher.uuid}>{researcherFullName(researcher)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        
                    </Grid>}
                    filterItems={filterItems} />
            }
            <Grid item xs={12}>
                <EnhancedTable  selectRow={(id:number) => fillRequest(id)} noHeader noSelectable 
                    rows={filteredRows} headCells={headCells} actions={actions}
                     />  
            </Grid>
        </Grid>
    );
};
