import React, { useState, useEffect } from 'react';
import MedicalHistoryEditor from './MedicalHistoryEditor';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { fetchTemplate, fetchTemplates } from '../../../../services/surveys';
import { Translate } from 'react-localize-redux';
import { SmartFieldType } from '..';

export interface Template {
    uuid: string;
    name: string;
    content: string;
}

export enum TemplatedMedicalHistoryEditorType {
    SURGERY_REPORT = 1,
}

interface TemplatedMedicalHistoryEditorViewProps {
    type:TemplatedMedicalHistoryEditorType,
    uuidInvestigation: string,
    updateElement: (index: number, element: SmartFieldType) => void;
    onBlur?: () => void;
}
interface TemplatedMedicalHistoryEditorProps extends TemplatedMedicalHistoryEditorViewProps {
    fetchTemplates: (uuidInvestigation: string, type:TemplatedMedicalHistoryEditorType) => Promise<Template[]>;
    fetchTemplate: (uuidInvestigation: string, templateId: string) => Promise<Template | null>;
}

const TemplatedMedicalHistoryEditor: React.FC<TemplatedMedicalHistoryEditorProps> = ({
    type,
    uuidInvestigation,
    updateElement,
    onBlur
}) => {
    return <TemplatedMedicalHistoryEditorView type={type} uuidInvestigation={uuidInvestigation} 
        updateElement={updateElement} onBlur={onBlur} 
        fetchTemplates={fetchTemplates} fetchTemplate={fetchTemplate} />
}

export const TemplatedMedicalHistoryEditorView: React.FC<TemplatedMedicalHistoryEditorProps> = ({
    type,
    uuidInvestigation,
    fetchTemplates,
    fetchTemplate,
    updateElement,
    onBlur
}) => {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
    const [templateContent, setTemplateContent] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchTemplatesAsync = async () => {
            try {
                setLoadingTemplates(true);
                const templatesData = await fetchTemplates(uuidInvestigation, type)
                setTemplates(templatesData);
            } catch (error) {
                console.error('Failed to fetch templates:', error);
            }
            finally{
                setLoadingTemplates(false);
            }
        };

        fetchTemplatesAsync();
    }, []);

    useEffect(() => {
        async function loadTemplate() {
            
            if (selectedTemplateId) {
                setIsLoading(true);
                try {
                    const content = await fetchTemplate(uuidInvestigation, selectedTemplateId);
                    setTemplateContent(content?.content || '');
                } catch (error) {
                    console.error('Failed to load template:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }

        loadTemplate();
    }, [selectedTemplateId]);

    const handleTemplateChange = (event: SelectChangeEvent) => {
        setSelectedTemplateId(event.target.value);
    };

    const handleContentChange = (newContent: string) => {
        setTemplateContent(newContent);
        console.log(newContent);
        updateElement(0, {
             "template_medical_history-template_id": selectedTemplateId,
            "template_medical_history-notes": newContent
        });
    };

    return (
        <div>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="template-select-label"><Translate id="general.selectTemplate" /></InputLabel>
                <Select
                    labelId="template-select-label"
                    id="template-select"
                    value={selectedTemplateId}
                    label={<Translate id="general.selectTemplate" />}
                    onChange={handleTemplateChange}
                    disabled={loadingTemplates}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {templates?.map(template => (
                        <MenuItem key={template.uuid} value={template.uuid}>
                            {template.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {(selectedTemplateId || templateContent) && (
                <MedicalHistoryEditor
                    value={templateContent}
                    loading={isLoading}
                    onChange={handleContentChange}
                    onBlur={onBlur}
                />
            )}
        </div>
    );
};

export default TemplatedMedicalHistoryEditor; 