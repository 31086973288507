import { Card, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RequestStatusToChip, statusToColor } from './RequestTable';
import { IRequest, IRequestServiceInvestigation, RequestStatus } from './types';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';
import { ColourChip } from '../../../components/general/mini_components-ts';
import Loader from '../../../components/Loader';
import axios from '../../../utils/axios';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { requestTypeToTranslation } from '../../../utils/index.jsx';
import Modal from '../../../components/general/modal';
import RequestLogTable from './RequestLogTable';

export const ChipContainer = styled.div`
    display:inline;
    margin-left:0.5rem;
`;

interface RequestInfoWithFetchProps {
    idSubmission:number, 
    uuidInvestigation:string
}

export const RequestInfoWithFetch: React.FC<RequestInfoWithFetchProps> = ({ idSubmission, uuidInvestigation }) => {
    const [request, setRequest] = useState<IRequest | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchRequest = async () => {
            setLoading(true);
            
            const response = await axios(`${import.meta.env.VITE_APP_API_URL}/hospital/${uuidInvestigation}/request/submission/${idSubmission}`, { headers: {"Authorization" : localStorage.getItem("jwt")} })
                    .catch(err => {console.log('Catch', err); return err;});
            if(response.status === 200){
                setRequest(response.data.request);
            }
            setLoading(false);
        }
        if(!request){
            fetchRequest();
        }
    }, [idSubmission])

    if(loading){
        return <Loader />
    }
    else{
        if(!request){
            return <Typography variant="h6">No request found</Typography>
        }
        return(
            <RequestInfoWithLocalize request={request} />
        )
    }
}

interface RequestInfoProps extends LocalizeContextProps{
    request:IRequest
}

const RequestInfo: React.FC<RequestInfoProps> = ({ request, translate }) => {
    const [openModal, setOpenModal] = useState(false);

    function renderServices(){
        let completedRequests:IRequestServiceInvestigation[] = [];
        let acceptedRequests:IRequestServiceInvestigation[] = [];
        let otherStateRequests:IRequestServiceInvestigation[] = [];
        if(request.status !== RequestStatus.COMPLETED){
            acceptedRequests = request.requestsServiceInvestigation.filter(r => r.status === RequestStatus.ACCEPTED);
            otherStateRequests = request.requestsServiceInvestigation.filter(r => r.status !== RequestStatus.ACCEPTED);
        }
        else{
            completedRequests = request.requestsServiceInvestigation;
        }

        const allRequests = [completedRequests, acceptedRequests, otherStateRequests];
        const infoRequests = [];
        for(let i = 0; i < allRequests.length; i++){
            if(allRequests[i].length > 0){
                //const statusString = i === 0 ? "completed" :  i === 1 ? "accepted" : "pending" ;
                const statusString = "type_of_procedure";
                const requests = request.requestsServiceInvestigation.map((reqSer) =>{
                    const serviceCode = reqSer.serviceInvestigation.service.code;
                    const typeService = requestTypeToTranslation(reqSer.serviceInvestigation.service.type);
                    return(<ChipContainer><ColourChip  rgbcolor={statusToColor(reqSer.status)} label={translate(`pages.hospital.services.tests.${typeService}.${serviceCode}`)}/></ChipContainer>)})
                infoRequests.push(<div><Typography variant="body2"><span style={{ fontWeight: 'bold' }}><Translate id={`pages.hospital.services.request.${statusString}`} />: </span>{requests}</Typography> </div>)
            }
        }
        return(
            <>
                {infoRequests}
            </>
        )
        
    }

    return (
        <>
            <Modal key="modal" fullWidth medium open={openModal} title="Request Info" closeModal={() => setOpenModal(false)}>
                    <RequestLogTable requestId={request.id} />
            </Modal>
            <Grid item xs={12}>
                <Card>
                    <div><Typography variant="body2"><span style={{ fontWeight: 'bold' }}><Translate id="pages.hospital.services.request.request_id" />: </span>{request.id}</Typography> </div>
                    {
                        request.typeSpecificData &&
                        <div>
                            <Typography variant="body2"><span style={{ fontWeight: 'bold' }}><Translate id="pages.hospital.services.request.surgery_indication" />: </span>{request.typeSpecificData.ict}</Typography>
                        </div>
                    }
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2"><span style={{ fontWeight: 'bold' }}><Translate id="pages.hospital.services.request.status" />: </span><RequestStatusToChip status={request.status} /></Typography>
                        <IconButton onClick={() => setOpenModal(true)} style={{ color: 'black' }}>
                            <InfoIcon />
                        </IconButton>
                    </div>
                    {
                        renderServices()
                    }
                </Card>  
        </Grid>
        </>
    );
};

const RequestInfoWithLocalize = withLocalize(RequestInfo);
export default RequestInfoWithLocalize;
